import request from '@/utils/request'; // 查询Withdraw列表

export function listWithdraw(query) {
  return request({
    url: '/api/v1/withdraw',
    method: 'get',
    params: query
  });
} // 查询Withdraw列表

export function listRefundType(query) {
  return request({
    url: '/api/v1/refundtypelist',
    method: 'get',
    params: query
  });
} // 查询Withdraw列表

export function listChannelWithdraw(query) {
  return request({
    url: '/api/v1/withdrawchannel',
    method: 'get',
    params: query
  });
} // 查询Withdraw详细

export function checkWithdraw() {
  return request({
    url: '/api/v1/withdrawcheck',
    method: 'get'
  });
} // 查询Withdraw详细

export function getWithdraw(ID) {
  return request({
    url: '/api/v1/withdraw/' + ID,
    method: 'get'
  });
}
export function getChannelWithdraw(ID) {
  return request({
    url: '/api/v1/withdrawchannel/' + ID,
    method: 'get'
  });
} // 新增Withdraw

export function addWithdraw(data) {
  return request({
    url: '/api/v1/withdraw',
    method: 'post',
    data: data
  });
} // 修改Withdraw

export function updateChannelWithdraw(data) {
  return request({
    url: '/api/v1/withdrawchannel/' + data.ID,
    method: 'put',
    data: data
  });
} // 修改Withdraw

export function updateWithdraw(data) {
  return request({
    url: '/api/v1/withdraw/' + data.ID,
    method: 'put',
    data: data
  });
} // 撤回订单

export function returnWithdraw(data) {
  return request({
    url: '/api/v1/returnwithdraw',
    method: 'delete',
    data: data
  });
}