var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: _vm.classObj,
      style: { "--current-color": _vm.$store.state.settings.theme }
    },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside }
          })
        : _vm._e(),
      _c("sidebar", {
        staticClass: "sidebar-container",
        style: {
          backgroundColor:
            _vm.$store.state.settings.themeStyle === "dark"
              ? _vm.variables.menuBg
              : _vm.variables.menuLightBg
        }
      }),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView }
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_c("navbar"), _vm.needTagsView ? _c("tags-view") : _vm._e()],
            1
          ),
          _c("app-main"),
          _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e()
        ],
        1
      ),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c(
        "audio",
        {
          attrs: {
            muted: "",
            controls: "controls",
            id: "s1",
            autoplay: "autoplay"
          }
        },
        [
          _c("source", {
            attrs: { src: require("../assets/s1.mp3"), type: "audio/ogg" }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }