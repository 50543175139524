import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import storage from '@/utils/storage';
export default function getPageTitle(pageTitle) {
  var app_info = storage.get('app_info');
  var title = app_info ? app_info.name : '后台管理系统';

  if (pageTitle) {
    return "".concat(pageTitle, " - ").concat(title);
  }

  return "".concat(title);
}