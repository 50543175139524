import "core-js/modules/es.object.to-string.js";
import { getSetting, updateSetting as _updateSetting } from '@/api/login';
import storage from '@/utils/storage';
var state = {
  info: storage.get('app_info')
};
var mutations = {
  SET_INFO: function SET_INFO(state, data) {
    state.info = data;
    storage.set('app_info', data);
  }
};
var actions = {
  settingDetail: function settingDetail(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      getSetting().then(function (response) {
        var data = response.data;
        commit('SET_INFO', data);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  updateSetting: function updateSetting(_ref2, info) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _updateSetting(info).then(function (response) {
        var data = response.data;
        commit('SET_INFO', data);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};