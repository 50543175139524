var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drawer-container" }, [
    _c(
      "div",
      [
        _c("div", { staticClass: "setting-drawer-content" }, [
          _c("div", { staticClass: "setting-drawer-title" }, [
            _vm._v(" 页面设置 ")
          ]),
          _c("div", { staticClass: "setting-drawer-block-checbox" }, [
            _c(
              "div",
              {
                staticClass: "setting-drawer-block-checbox-item",
                on: {
                  click: function($event) {
                    return _vm.handleTheme("light")
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/light.svg"), alt: "light" }
                }),
                _vm.themeStyle === "light"
                  ? _c(
                      "div",
                      {
                        staticClass: "setting-drawer-block-checbox-selectIcon",
                        staticStyle: { display: "block" }
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "anticon anticon-check",
                            attrs: { "aria-label": "图标: check" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  viewBox: "64 64 896 896",
                                  "data-icon": "check",
                                  width: "1em",
                                  height: "1em",
                                  fill: _vm.theme,
                                  "aria-hidden": "true",
                                  focusable: "false"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _c(
              "div",
              {
                staticClass: "setting-drawer-block-checbox-item",
                on: {
                  click: function($event) {
                    return _vm.handleTheme("dark")
                  }
                }
              },
              [
                _c("img", {
                  attrs: { src: require("@/assets/dark.svg"), alt: "dark" }
                }),
                _vm.themeStyle === "dark"
                  ? _c(
                      "div",
                      {
                        staticClass: "setting-drawer-block-checbox-selectIcon",
                        staticStyle: { display: "block" }
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "anticon anticon-check",
                            attrs: { "aria-label": "图标: check" }
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  viewBox: "64 64 896 896",
                                  "data-icon": "check",
                                  width: "1em",
                                  height: "1em",
                                  fill: _vm.theme,
                                  "aria-hidden": "true",
                                  focusable: "false"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ]
            )
          ])
        ]),
        _c("el-divider"),
        _c("div", { staticClass: "setting-drawer-content" }, [
          _c("div", { staticClass: "setting-drawer-title" }, [
            _vm._v(" 主题设置 ")
          ]),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("主题颜色")]),
              _c("theme-picker", {
                staticStyle: {
                  float: "right",
                  height: "26px",
                  margin: "-3px 8px 0 0"
                },
                on: { change: _vm.themeChange }
              })
            ],
            1
          )
        ]),
        _c("el-divider"),
        _c("div", { staticClass: "setting-drawer-content" }, [
          _c("div", { staticClass: "setting-drawer-title" }, [
            _vm._v(" 布局设置 ")
          ]),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("开启任务栏")]),
              _c("el-switch", {
                staticClass: "drawer-switch",
                attrs: { "active-color": _vm.activeColor },
                model: {
                  value: _vm.tagsView,
                  callback: function($$v) {
                    _vm.tagsView = $$v
                  },
                  expression: "tagsView"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("Header 固定")]),
              _c("el-switch", {
                staticClass: "drawer-switch",
                attrs: { "active-color": _vm.activeColor },
                model: {
                  value: _vm.fixedHeader,
                  callback: function($$v) {
                    _vm.fixedHeader = $$v
                  },
                  expression: "fixedHeader"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "drawer-item" },
            [
              _c("span", [_vm._v("侧边栏Logo")]),
              _c("el-switch", {
                staticClass: "drawer-switch",
                attrs: { "active-color": _vm.activeColor },
                model: {
                  value: _vm.sidebarLogo,
                  callback: function($$v) {
                    _vm.sidebarLogo = $$v
                  },
                  expression: "sidebarLogo"
                }
              })
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }